<template>
    <div>
        <Loader v-if="loading"></Loader>
        <CRow v-else>
            <CCol lg="12">
                <AbstractTable :fields="fields" :items="quests" :actions="actions" title="Cuestionarios"
                    labelItemPerPage="Cuestionarios por página:"
                    :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir cuestionario', event: 'addElement' }" @addElement="addQuest"
                    @editInfo="editQuest" @seeInfo="handleInfo" @showDeleteModal="handleModal" :noResults="'No se han encontrado cuestionarios'"/>
                    
               <DeleteModal :modalContent="modalContent" @delete="deleteQuestData(questId)" :showModal="deleteModal" @closeModal="closeModal" />
            </CCol>
        </CRow>
    </div>
</template>
  
<script>
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getQuestsData, deleteQuest } from '@/services/quests';
import { limitCharacters,generateField } from '@/utils/utils';
export default {
    name: "questTable",
    components: { AbstractTable, DeleteModal, Loader },
    data() {
        return {
            quests: [],
            quest: {},
            questId: 0,
            questName: '',
            loading: true,
            deleteModal: false,
            fields: [
                generateField("quest_title", "Nombre del cuestionario", {styleData: { textAlign: 'center'}}),
                generateField("quest_description", "Descripción del cuestionario"),
                generateField("need_device", "Necesidad del hardware", {type: "icon", styleData: { textAlign: 'center'}}),
                generateField("periodicity", "Periodicidad (días)", {styleData: { textAlign: 'center'}}),
                generateField("actions", 'Acciones', { sorter: false, filter: false })
            ],
            actions: [
                {
                    key: 'edit',
                    label: 'Editar',
                    isAdmin: !this.$store.state.isAdmin,
                    event: 'editInfo',
                    classButton: 'btn btn-success btn-sm btn-pill'
                },
                {
                    key: 'info',
                    label: 'Ver contenido',
                    event: 'seeInfo',
                    classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
                },
                {
                    key: 'delete',
                    label: 'Eliminar',
                    isAdmin: !this.$store.state.isAdmin,
                    event: 'showDeleteModal',
                    classButton: 'btn btn-delete btn-sm btn-pill'
                },
            ],
            modalContent: {
            title: 'Eliminar cuestionario',
            textBody: '',
            buttons: [
                {
                  textButton: 'Cerrar',
                  color: 'secondary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                },
            ]
      }
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        receiveData() {
            this.getQuests();
        },
        /**
         * Función en la que se obtienen los cuestionarios y que también
         * llama el metodo receiveData para cargarlos.
         * 
         * @returns {object[]} Los cuestionarios obtenidos.
         */
        getQuests() {
            getQuestsData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((result) => {
                this.quests = result
                this.quests.forEach((quest) => {
                    quest.quest_id = limitCharacters(quest.id, 100)
                    quest.quest_title = limitCharacters(quest.title, 100)
                    quest.quest_description = quest.description
                    quest.periodicity = limitCharacters(quest.periodicity, 100)
                    quest.icon = quest.need_device == 1 ? 'cil-check' : 'cil-x';
                })
                this.loading = false;
            });
        },
        /**
         * Este método se llama cuando el usuario pulsa el botón Crear nuevo cuestionario y redirige a la vista con el formulario para poder crear uno.
         */
        addQuest() {
            this.$router.push(`/quest/create/`);
        },
         /**
          * Método para controlar lo que se muestra en el Modal de Eliminar cuestionario.
          */
        handleModal(item) {
            this.questId = item.quest_id;
            this.modalContent.textBody = `¿Está seguro de que desea eliminar el cuestionario: <strong>"${item.quest_title}</strong>"? Esta acción no se puede deshacer.`,
            this.deleteModal = true;
        },
        /**
         * Este método se llama cuando el usuario pulsa el botón Editar y redirige a la vista con el formulario para poder editar uno.
         */
        editQuest(item) {
            this.$router.push(`/quest/edit/${item.quest_id}`)
        },
        /**
         * Este método se llama cuando el usuario pulsa el botón Ver contenido y redirige a la vista para poder consultar la información del cuestionario.
         */
        handleInfo(item) {
            this.$router.push(`/quest/info/${item.quest_id}`)
        },
         /**
         * Este metodo eliminará el cuestioanrio, mandando por la URL el id
         * 
         * @param {number} id - El id del cuestionario a eliminar
         */
        deleteQuestData(id) {
            deleteQuest(id).then(() => {
                this.getQuests()
            })
            this.deleteModal = false;
        },
         /**
         * Función para cerrar el Modal.
         */
        closeModal(){
            this.deleteModal = false;
        }
    },
};
</script>